import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg width="90.701" height="19.703" version="1.1" viewBox="0 0 23.998 5.2131" xmlns="http://www.w3.org/2000/svg">
     <g transform="translate(-10.727 -291.61)">
      <g transform="matrix(.0024142 0 0 .0024142 10.727 291.66)" clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision">
       <path  d="m4448.2 1584.3c13-48.69 24.27-24.26 51.56-124.47l18.86-51.56c0 36.81 3.01 83.85 1.49 118.85-2.73 62.77-10.46 57.18-71.9 57.18zm469.42-258.18c0-172.24-28.65-533.77 91.24-624.69 43.06-32.66 100.95-43.98 132.31 1.82 55.74 81.38 34.63 505.68 34.63 634.61 0 353.58-258.18 283.47-258.18-11.74zm-1067.9 222.97c-75.43 0-58.68-116.74-58.68-211.24 0-115.39 32.83-178.78 140.82-187.77 0 56.58 18.25 399-82.15 399zm551.56-1502.1h117.36v1255.7h-35.21c-6.92 83.08-20.24 222.97-93.89 222.97-78.82 0-58.68-195.64-58.68-281.65 0-308.62 73.68-668.92-363.8-668.92-149.97 0-397.37-10.86-495.26 114.98-81.55 104.85-68.94 330.96 131.46 330.96 171.5 0 199.51-99.88 199.51-211.24 0-57.85-12.78-58.68-70.41-58.68-2.45-29.39-56.22-140.82 105.62-140.82 112.92 0 93.88 383.4 93.88 504.62-172.04 0-403.55-13.97-506.99 114.99-105.83 131.95 48.55 319.22-208.87 319.22-59.88 0-70.41-142.88-70.41-211.24v-692.39h281.65v-46.94h-281.65v-375.53c-55.96 1.25-152.82 32.31-201.87 44.58-69.09 17.27-130.69 33.83-197.14 49.31v281.65h-117.36v46.94h117.36v715.86c0 340.22 437.26 305.78 552.24 247.12 55.85-28.49 59.7-52.53 104.94-82.83 99.24 72.71 52.1 117.36 340.33 117.36 100.06 0 168.39-57.63 187.77-140.82 9.41 10.83 10 11.12 20.82 26.12 8.31 11.52 11.93 18.6 18.93 28.01 19.92 26.76 20.74 27.38 49.78 44.1 151.79 87.37 335.52 19.12 415.09 19.12h457.68l13.74-93.55 31.07 48.73c84.4 109.53 436.53 94.42 554.16-60.34 121.04-159.25 105.15-294.88 105.15-493.35 0-230.43-134.91-445.95-363.8-445.95-84.97 0-144.06-4.23-218.59 51.32-45.8 34.13-54.23 56.41-82.4 93.64-17.44 23.06-5.06 6.9-15.87 19.34v-739.33h-516.36v46.94z" fill="#7A8085"/>
       <path  d="m6607.6 316.86v269.91h-281.65v46.94h140.82l-180.57 511.82c-16.81 48.08-50.24 133.79-54.14 180.57-41.86-30.67-106.68-279.13-124.66-333.02l-110.05-359.37h105.62v-46.94h-621.98v46.94c104.95 0 80.07-18.07 168.68 230.32l184.74 495.91c28.4 78.36 73.14 176.37 92.52 259.54h187.77c-5.46 65.58-137.3 481.15-305.12 481.15-96.27 0-88.86-26.45-70.41-105.62h82.15c0-93.1 22.49-234.71-176.03-234.71-258.58 0-223.92 399 46.94 399 192.26 0 293.45-26.01 392.33-264.85l368.97-1051c70.76-212.43 41.74-198.01 154.05-198.01v715.86c0 268 223.52 281.65 410.74 281.65 181.42 0 305.12-145.69 305.12-328.59-78.26 6.51-24.59 30.05-75.89 135.35-47.52 97.54-94.27 99.36-170.56 99.36-59.87 0-70.41-142.88-70.41-211.24v-692.39h269.92c3.89 46.75 64 191.17 83.92 244.67l268.14 729.37h187.77c-5.46 65.58-137.3 481.15-305.12 481.15-96.27 0-88.86-26.45-70.41-105.62h82.15c0-93.1 22.48-234.71-176.03-234.71-258.57 0-223.91 399 46.94 399 192.26 0 293.45-26.01 392.34-264.85 98.69-238.36 310.25-872.96 401.2-1147.9 14.03-42.4 31.27-76.78 39.68-112.88h105.62v-46.94h-305.12v46.94h140.83l-180.57 511.82c-16.81 48.08-50.25 133.79-54.14 180.57-46.54-34.09-156.91-440.43-180.31-512.08-17.04-52.19-50.11-128.85-54.4-180.31h105.62v-46.94h-434.21c-136.76 0-228.38 11.74-363.8 11.74v-375.53c-55.96 1.25-152.82 32.31-201.87 44.58-69.09 17.27-130.69 33.83-197.14 49.31z" fill="#7A8085"/>
       <path  d="m422.98 1126.6c0-125.97-2.37-481.15 105.62-481.15 98.18 0 152.56 47.33 152.56 164.3v563.3c0 118.53-124.7 234.47-209.08 138.74-44.66-50.67-49.1-282.63-49.1-385.18zm129.09-1079.7h129.09v610.24c-136.71-91.54-107.6-82.15-293.39-82.15-454.35 0-463.13 731.31-262.88 955.27 0.53 0.59 112.33 112.65 227.67 112.65 156.74 0 237.16-1.75 325.68-132.01 13.77-20.26 1.17-5.04 14.65-20.55 3.09 37.14 15.45 94.66 23.47 129.09h1044.4c-4.75-57.11 0.67-46.94-129.09-46.94v-974.04h-528.1v46.94h129.09v927.1h-105.62c-44.87 0-4.27-3.6-35.21 11.73v-1584.3h-539.83v46.94z" fill="#7A8085"/>
       <path  d="m8602.6 1138.3c0-178.78-12.67-492.89 129.09-492.89 81.93 0 129.09 92.94 129.09 176.03v504.62c0 187.51-233.42 364.44-246.31 58.54-3.87-91.72-11.87-146.7-11.87-246.31zm-422.48-35.21c0 128.41 30.28 284.79 79.55 366.4 98.47 163.11 198.42 173.42 366.4 173.42 55.65 0 108.8-23.8 141.38-46.39l69.48-71.35c1.96-2.86 7.35-11.55 9.2-14.27 13.76-20.25 1.17-5.04 14.65-20.55v598.51h-211.24v46.94h727.6v-46.94h-105.62v-1443.5h140.82v927.1h-117.36v46.94h645.45c-4.75-57.11 0.67-46.94-129.09-46.94v-974.04h-915.36c-9.64 41.38-11.74 38.56-11.74 93.88-92.41-61.88-41.67-117.35-305.12-117.35-210.26 0-329.39 141.7-373.58 318.81-12.3 49.29-25.43 152.5-25.43 209.28z" fill="#7A8085"/>
       <path  d="m2089.4 985.78c0-113.79-8.04-363.8 117.36-363.8 74.4 0 90.7 58.64 93.85 129.12l11.77 269.89h-222.97v-35.21zm93.88 657.18c223.48 0 447.79-47.65 508.23-292.73 8.96-36.32 14.04-43.2-38.81-47.6-21.3 255.97-435.12 390.05-524.26 113.52-28.8-89.34-39.04-233.86-39.04-348.23h610.24c0-153.11-1.38-264.3-108.21-372.94-130.6-132.83-249.04-119.94-419.88-119.94-201.17 0-388.6 117-464.83 309.71-116.81 295.3 11.73 758.21 476.56 758.21z" fill="#7A8085"/>
       <path  d="m9400.6 211.24c0 81.18-10.26 142.93 67.97 201.95 88.64 66.87 366.24 90.72 366.24-178.48 0-263.75-434.21-232.6-434.21-23.47z" fill="#7A8085"/>
       <path  d="m1221 211.24c0 81.18-10.26 142.93 67.97 201.95 88.64 66.87 366.24 90.72 366.24-178.48 0-263.75-434.21-232.6-434.21-23.47z" fill="#7A8085"/>
      </g>
     </g>
     <defs>
       <clipPath id="clip0">
         <rect width="191.156" height="23" fill="white" />
       </clipPath>
     </defs>
    </svg>
  );
}
